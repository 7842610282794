<template>
  <v-autocomplete
    v-model="select"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    cache-items
    :hide-no-data="search === null"
    no-data-text="No se encontraron resultados"
    label="Gerente de ventas"
    item-text="name"
    item-value="_id"
    placeholder="Buscar un asesor por nombre"
    return-object
    @change="$emit('input', select)"
  />
</template>

<script>
import { searchUserName } from "../../api/user-api";

/**
 * Componente de autocompletado para buscar Asesor.
 *
 * @component
 * @description Este componente proporciona una interfaz de autocompletado
 * para buscar y seleccionar leads por su nombre.
 *
 * @returns {void}
 */
export default {
  name: "AutocompleteAsesor",
  props: { value: Object, initialValue: Object, initialItems: Array },
  data() {
    return {
      timer: null,
      delay: 500,
      loading: false,
      items: this.initialItems || [],
      search: null,
      select: this.initialValue || null
    };
  },
  watch: {
    search(val) {
      if (val && val.trim() !== "" && val !== this.select) {
        this.querySelections(val);
      }
    },
    initialItems(val) {
      this.items = val;
    },
    initialValue(val) {
      this.select = val;
    }
  },
  methods: {
    querySelections(v) {
      clearTimeout(this.timer);
      this.loading = true;
      // Debounce the search
      this.timer = setTimeout(async () => {
        try {
          const res = await searchUserName(v);
          this.items = res.data.users;
        } catch (error) {
          this.items = [];
        } finally {
          this.loading = false;
        }
      }, this.delay);
    }
  }
};
</script>
