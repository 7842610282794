<template>
  <v-container>
    <ModalPricingRules
      :listPropertyCode="listPropertyCode"
      :listDiscount="listDiscount"
      @action="save"
      @close="close"
      v-model="pricing_rules"
      :type="formTitlePR"
      :show="show"
      ref="modalPricingRules"
    />
    <v-row>
      <v-spacer />
      <v-btn color="primary" dense @click="addRulePrice">
        <v-icon small>mdi-plus</v-icon>
        Agregar
      </v-btn>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="12" sm="10">
        <v-simple-table
          :dense="dense"
          :fixed-header="fixedHeader"
          :height="height"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Disponible</th>
                <th class="text-left">Tipo de contratos</th>
                <th class="text-left">Propiedades</th>
                <th class="text-left">Descripción</th>
                <th class="text-left">Forma de pago</th>
                <th class="text-left">Enganche</th>
                <th class="text-left">Descuento</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in real_estate_pricing_rules" :key="item._id">
                <td>
                  <v-checkbox
                    :input-value="disponibility(item.status)"
                    color="indigo darken-3"
                    light
                    hide-details
                    @change="changeDisponibility(item)"
                  ></v-checkbox>
                </td>
                <td>
                  {{ valueProcessType[item.process_type] }}
                </td>
                <td>{{ item.propertys_code.toString() }}</td>
                <td>{{ item.description }}</td>
                <td>
                  {{ valueMethodPaymet[item.method_payment] }}
                </td>
                <td>
                  {{
                    item.percentage_down_payment
                      ? `${item.percentage_down_payment} %`
                      : "-"
                  }}
                </td>
                <td>
                  {{ getDiscountAssigned(item.discounts) }}
                </td>
                <td>
                  <v-btn
                    @click="editItemPR(item)"
                    fab
                    small
                    elevation
                    color="blue lighten-5"
                  >
                    <v-icon color="cyan darken-4">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  UpdatePricingRule,
  CreateRulesPricing
} from "@/api/real-estate-pricing-rules-api.js";
import ModalPricingRules from "./ModalPricingRules.vue";

export default {
  name: "PricingRulesConfig",
  components: {
    ModalPricingRules
  },
  props: {
    real_estate_pricing_rules: { type: Array, default: () => [] },
    real_estate_discount: { type: Array, default: () => [] },
    listDiscount: { type: Array, default: () => [] },
    listPropertyCode: { type: Array, default: () => [] },
    realEstateID: { type: String, required: true }
  },
  data() {
    return {
      show: false,
      pricing_rules: null,
      dense: false,
      fixedHeader: false,
      height: "400px",
      valueProcessType: {
        sale: "Venta",
        "pre-sale": "Preventa"
      },
      valueMethodPaymet: {
        financing: "Financiamiento",
        "financing-interest":
          "Financiamiento con intereses (sobre saldos insolutos)",
        counted: "Contado"
      },
      editedIndexPR: -1,
      updatePR: false
    };
  },
  computed: {
    formTitlePR() {
      return this.editedIndexPR === -1 || this.updatePR === false
        ? "Crear"
        : "Actualizar";
    },
    propertyAllSelected() {
      return (
        this.pricing_rules.propertys_code.length ===
        this.listPropertyCode.length
      );
    },
    propertySelected() {
      return (
        this.pricing_rules.propertys_code.length > 0 &&
        !this.propertyAllSelected
      );
    }
  },
  methods: {
    disponibility(value) {
      if (value == 1) return true;
      return false;
    },
    getDiscountAssigned(discount) {
      const listDiscount = [];
      discount.forEach(rDiscount => {
        listDiscount.push(`${rDiscount.discount}% `);
      });
      return listDiscount.toString();
    },

    changeDisponibility(item) {
      const status = item.status == 1 ? 2 : 1;
      //Si todo sale bien se cambia el status en los datos de la tabla y de lo contrario no se cambia nada..
      this.$snotify.async(
        "Actualizando Regla de Precio",
        "Actualizando ...",
        () =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              UpdatePricingRule({ status }, item._id)
                .then(() => {
                  item.status = status;
                  resolve({
                    title: "Regla de precio",
                    body: `Actualizado correctamente`,
                    config: {
                      closeOnClick: true,
                      titleMaxLength: 30,
                      timeout: 2000
                    }
                  });
                })
                .catch(err => {
                  reject({
                    title: "Error!!!",
                    body: `Ocurrio un problema en el proceso. ${err}`,
                    config: {
                      closeOnClick: true,
                      timeout: 4000
                    }
                  });
                })
                .finally();
            }, 500);
          })
      );
    },
    editItemPR(item) {
      this.editedIndexPR = this.real_estate_pricing_rules.indexOf(item);
      this.pricing_rules = Object.assign({}, item);
      this.pricing_rules.discounts = item.discounts.map(
        discount => discount["_id"]
      );
      this.updatePR = true;
      this.show = true;
    },
    addRulePrice() {
      this.pricing_rules = null;
      this.show = true;
    },
    save() {
      if (this.editedIndexPR > -1 && this.updatePR === true) {
        const rulespricingId = this.real_estate_pricing_rules[
          this.editedIndexPR
        ]["_id"];
        this.handleUpdateRulesPricing(this.pricing_rules, rulespricingId);
        this.updatePR = false;
      } else {
        this.handleCreateRulesPricing(this.pricing_rules, this.realEstateID);
      }
      this.show = false;
    },
    handleUpdateRulesPricing(pricing_rules, pricingRuleId) {
      let self = this;
      this.$snotify.async(
        "Actualizando Regla de Precio",
        "Actualizando ...",
        () =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              UpdatePricingRule(pricing_rules, pricingRuleId)
                .then(() => {
                  self.real_estate_discount.map(dis => {
                    const idxExistDiscount = pricing_rules.discounts.indexOf(
                      dis["_id"]
                    );
                    if (idxExistDiscount !== -1) {
                      pricing_rules.discounts[idxExistDiscount] = dis;
                    }
                  });
                  Object.assign(
                    self.real_estate_pricing_rules[self.editedIndexPR],
                    pricing_rules
                  );
                  resolve({
                    title: "Regla de precio",
                    body: `Actualizando correctamente`,
                    config: {
                      closeOnClick: true,
                      titleMaxLength: 30,
                      timeout: 4000
                    }
                  });
                })
                .catch(err => {
                  reject({
                    title: "Error!!!",
                    body: `Ocurrio un problema en el proceso. ${err}`,
                    config: {
                      closeOnClick: true,
                      timeout: 4000
                    }
                  });
                })
                .finally();
            }, 500);
          })
      );
    },
    handleCreateRulesPricing(pricing_rules, realEstateID) {
      let self = this;
      this.$snotify.async(
        "Agregando Regla de Precio",
        "Agregando ...",
        () =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              CreateRulesPricing(pricing_rules, realEstateID)
                .then(rulePrice => {
                  if (!rulePrice.PricingRules) throw new Error();
                  self.real_estate_pricing_rules.push(rulePrice.PricingRules);
                  resolve({
                    title: "Regla de precio",
                    body: `Agregado correctamente`,
                    config: {
                      closeOnClick: true,
                      titleMaxLength: 30,
                      timeout: 4000
                    }
                  });
                })
                .catch(err => {
                  reject({
                    title: "Error!!!",
                    body: `Ocurrio un problema en el proceso. ${err}`,
                    config: {
                      closeOnClick: true,
                      timeout: 4000
                    }
                  });
                })
                .finally();
            }, 500);
          })
      );
    },
    close() {
      this.show = false;
      this.pricing_rules = null;
    }
  }
};
</script>
