<template>
  <v-row justify="space-around" no-gutters>
    <v-container>
      <v-form ref="form" action="">
        <h3>Detalles</h3>
        <v-row align="center" justify="center">
          <v-col lass="shrink" style="min-width: 220px" cols="12" md="6" sm="6">
            <label>¿Precios incluyen IVA?</label>
            <v-radio-group v-model="includeIva" row>
              <v-radio label="Si" :value="true" color="#042956"></v-radio>
              <v-radio label="No" :value="false" color="#042956"></v-radio>
            </v-radio-group>
            <div>
              <v-text-field
                :disabled="disabled"
                v-model="iva"
                prefix="%"
                type="number"
                step="0.01"
                color="#042956"
              ></v-text-field>
            </div>
          </v-col>
          <v-col lass="shrink" style="min-width: 220px" cols="12" md="6" sm="6">
          </v-col>
        </v-row>
        <h3>Preview PDF</h3>
        <!--Vista del pdf -->

        <v-row justify="center">
          <v-col
            class="shrink"
            style="min-width: 220px"
            cols="12"
            md="6"
            sm="6"
          >
            <preview-print-pdf
              v-if="typeOfQuote == 'one'"
              :hideCurrency="styles_pdf.hideCurrency"
              :bgOne="styles_pdf.container_one"
              :bgTwo="styles_pdf.container_two"
              :textColor="styles_pdf.text_color"
              :titleColor="styles_pdf.title_color"
              :titleTwoColor="styles_pdf.title2_color"
              :textThreeColor="styles_pdf.text3_color"
              :imgEstate="previewMediaFeature"
              :propertyData="listPropertys"
              :logoDesing="styles_pdf.logo"
              :imgEstateDesing="styles_pdf.featured_image"
              :realEstateGroupLogoDesing="styles_pdf.realEstateGroupLogo"
              :logoFooter="styles_pdf.logo_footer"
              :defaultActive="defaultActive"
            />

            <preview-advanced-print-pdf
              v-if="typeOfQuote == 'two'"
              :hideCurrency="styles_pdf.hideCurrency"
              :bgOne="styles_pdf.container_one"
              :bgTwo="styles_pdf.container_two"
              :textColor="styles_pdf.text_color"
              :titleColor="styles_pdf.title_color"
              :titleTwoColor="styles_pdf.title2_color"
              :textThreeColor="styles_pdf.text3_color"
              :imgEstate="previewMediaFeature"
              :propertyData="listPropertys"
              :logoDesing="styles_pdf.logo"
              :imgEstateDesing="styles_pdf.featured_image"
              :realEstateGroupLogoDesing="styles_pdf.realEstateGroupLogo"
              :logoFooter="styles_pdf.logo_footer"
              :defaultActive="defaultActive"
              :unitTypes="unitType"
              :realEstateDevelopment="realEstateData"
            />
          </v-col>
          <v-col lass="shrink" style="min-width: 220px" cols="12" md="6" sm="6">
            <h1 class="text-center mb-4">CONFIGURACIÓN</h1>
            <v-row align="center" justify="center">
              <!-- <h1 class="text-center">Base</h1> -->
              <v-col
                lass="shrink"
                style="min-width: 220px"
                cols="12"
                md="6"
                sm="6"
              >
                <color-picker-selected
                  label="Fondo Primario"
                  :defColor="styles_pdf.container_one"
                  @setColorSelected="
                    value => (styles_pdf.container_one = value)
                  "
                >
                </color-picker-selected>
              </v-col>
              <v-col
                lass="shrink"
                style="min-width: 220px"
                cols="12"
                md="6"
                sm="6"
              >
                <color-picker-selected
                  label="Fondo Secundario"
                  :defColor="styles_pdf.container_two"
                  @setColorSelected="
                    value => (styles_pdf.container_two = value)
                  "
                >
                </color-picker-selected>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                lass="shrink"
                style="min-width: 220px"
                cols="12"
                md="6"
                sm="6"
              >
                <color-picker-selected
                  label="Color del título"
                  :defColor="styles_pdf.title_color"
                  @setColorSelected="value => (styles_pdf.title_color = value)"
                >
                </color-picker-selected>
              </v-col>
              <v-col
                lass="shrink"
                style="min-width: 220px"
                cols="12"
                md="6"
                sm="6"
              >
                <color-picker-selected
                  label="Color del título 2"
                  :defColor="styles_pdf.title2_color"
                  :disableInput="false"
                  @setColorSelected="value => (styles_pdf.title2_color = value)"
                >
                </color-picker-selected>
              </v-col>
              <v-col
                lass="shrink"
                style="min-width: 220px"
                cols="12"
                md="6"
                sm="6"
              >
                <color-picker-selected
                  label="Color del título 3"
                  :defColor="styles_pdf.text3_color"
                  @setColorSelected="value => (styles_pdf.text3_color = value)"
                >
                </color-picker-selected>
              </v-col>
              <v-col
                lass="shrink"
                style="min-width: 220px"
                cols="12"
                md="6"
                sm="6"
              >
                <color-picker-selected
                  label="Color del texto"
                  :defColor="styles_pdf.text_color"
                  @setColorSelected="value => (styles_pdf.text_color = value)"
                >
                </color-picker-selected>
              </v-col>
              <!-- Ocultar la divisa -->
              <v-col
                lass="shrink"
                style="min-width: 220px"
                cols="12"
                md="6"
                sm="6"
              >
                <v-checkbox
                  v-if="typeOfQuote == 'one'"
                  v-model="styles_pdf.hideCurrency"
                  label="Ocultar Divisa"
                ></v-checkbox>
              </v-col>
            </v-row>
            <AutocompleteAsesor
              v-model="businessManager"
              :initialValue="businessManager"
              :initialItems="initialListUsers"
            />
            <!--  Imagenes: logo Desarrollo, logo Inmobiliria, Imagen Destacada -->
            <ImagePickerSelected
              sizeRecommended="Tamaño recomendado: 600 x 350 px, png con transparencia"
              label="Logo desarrollo"
              :previousImage="styles_pdf.logo"
              :defaultActive="defaultActive"
              @setNewImage="value => (styles_pdf.logo = value)"
            >
            </ImagePickerSelected>

            <ImagePickerSelected
              v-if="typeOfQuote == 'one'"
              sizeRecommended="Tamaño recomendado: 600 x 350 px, png con transparencia"
              label="Logo comercializadora (opcional)"
              :previousImage="styles_pdf.realEstateGroupLogo"
              :defaultActive="defaultActive"
              @setNewImage="value => (styles_pdf.realEstateGroupLogo = value)"
            >
            </ImagePickerSelected>
            <ImagePickerSelected
              sizeRecommended="Orientación horizontal de alta calidad (png o jpg)"
              label="Imagen destacada desarrollo"
              :previousImage="styles_pdf.featured_image"
              :defaultActive="defaultActive"
              @setNewImage="value => (styles_pdf.featured_image = value)"
            >
            </ImagePickerSelected>

            <ImagePickerSelected
              sizeRecommended="Tamaño recomendado: 600 x 350 px, png con transparencia"
              label="Logo pie de pagina (opcional)"
              :previousImage="styles_pdf.logo_footer"
              :defaultActive="defaultActive"
              @setNewImage="value => (styles_pdf.logo_footer = value)"
            >
            </ImagePickerSelected>
          </v-col>
        </v-row>

        <!-- Botones de guardar y valores por default -->
        <v-row>
          <v-col>
            <v-btn color="#262D3C" @click="defaultStyle" dark>
              Valores por default
            </v-btn>
          </v-col>
          <v-col>
            <v-btn color="success" @click="handleUpdateMiscellaneous" dark>
              {{ formTitleQuote }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import PreviewPrintPdf from "../real-estate-edits-components/PreviewPrintPdf.vue";
import PreviewAdvancedPrintPdf from "../real-estate-edits-components/PreviewAdvancedPrintPdf.vue";
import AutocompleteAsesor from "../../quoter/AutocompleteAsesor.vue";
import { CloudinaryUploaderForm } from "@/api/media-api.js";

const DEFAULT_STYLES = {
  container_one: "#262D3C",
  container_two: "#FFFFFF",
  //container_separator: "#000000",
  text_color: "#FFFFFF",
  title_color: "#262D3C",
  title2_color: "#262D3C",
  text3_color: "#262D3C",
  featured_image: null,
  logo: null,
  realEstateGroupLogo: null,
  logo_footer: null,
  //Se agrega la opcion de poder ocultar la divisa
  hideCurrency: false
};

export default {
  name: "QuoteSettings",
  components: {
    ColorPickerSelected: () =>
      import(
        "@/components/real-estate-development/quote/ColorPickerSelected.vue"
      ),
    ImagePickerSelected: () =>
      import(
        "@/components/real-estate-development/quote/ImagePickerSelected.vue"
      ),
    PreviewPrintPdf,
    PreviewAdvancedPrintPdf,
    AutocompleteAsesor
  },
  props: {
    disabled: Boolean,
    previewMediaFeature: String,
    listPropertys: Array,
    realEstateData: Object,
    cloudinaryConfig: Object
  },
  data() {
    return {
      includeIva: false,
      typeOfQuote: null,
      unitType: "office",
      styles_pdf: { ...DEFAULT_STYLES },
      defaultActive: false,
      businessManager: null,
      initialListUsers: [],
      iva: 0,
      styles_view: {
        element: "",
        style: ""
      },
      groupId: null
    };
  },
  mounted() {
    this.setPropertiesData();
  },
  watch: {
    rearEstateData: {
      handler() {
        this.setPropertiesData();
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters("contacts", {
      contactsListLoading: "ContactsListLoading",
      contactById: "contactById"
    }),
    formTitleQuote() {
      return this.editedIndexG === -1 ? "Guardar" : "Actualizar ";
    }
  },
  methods: {
    setPropertiesData() {
      const { unit_types, miscellaneous } = this.realEstateData;
      //Typo de unidad...
      if (unit_types) this.unitType = unit_types;

      if (miscellaneous && miscellaneous.quote) {
        const { quote, business_manager } = miscellaneous;
        //Cotizador antiguo
        if (quote.active === "on") {
          this.activeQuote = quote.active;
          this.typeOfQuote = "one";
        }

        //Cotizador nuevo
        if (quote.active_two === "on") {
          this.activeQuote = quote.active_two;
          this.typeOfQuote = "two";
        }

        if (quote.vat > 0) {
          this.includeIva = quote.include_vat;
          this.iva = quote.vat;
        }
        if (quote.styles_pdf) {
          this.styles_pdf = {
            ...this.styles_pdf,
            ...quote.styles_pdf
          };
        }
        if (business_manager) {
          const businessManager = this.contactById(business_manager);
          this.initialListUsers = businessManager ? [businessManager] : [];
          this.businessManager = businessManager || null;
        }
      }
    },
    defaultStyle() {
      var resultado = window.confirm(
        "¿Seguro que quiere restaurar los valores por default?"
      );
      if (resultado === true) {
        this.defaultActive = true;

        this.styles_pdf = { ...DEFAULT_STYLES };
        this.defaultActive = false;
      }
      //alert("¿Seguro que quiere restaurar los valores por default?");
    },
    imgValidate(img) {
      return img && typeof img === "object";
    },
    uploadImg(file) {
      let self = this;
      return new Promise((resolve, rej) => {
        CloudinaryUploaderForm(file, self.cloudinaryConfig).then(res => {
          const fileCloudinary = res.fileInCloudinary;
          if (Object.keys(fileCloudinary).length > 0) {
            resolve(fileCloudinary.secure_url);
          } else {
            rej(null);
          }
        });
      });
    },
    async handleUpdateMiscellaneous() {
      //Subir datos
      //Saber si es un file

      if (this.imgValidate(this.styles_pdf.featured_image)) {
        this.styles_pdf.featured_image = await this.uploadImg(
          this.styles_pdf.featured_image
        );
      }
      if (this.imgValidate(this.styles_pdf.logo)) {
        this.styles_pdf.logo = await this.uploadImg(this.styles_pdf.logo);
      }
      if (this.imgValidate(this.styles_pdf.realEstateGroupLogo)) {
        this.styles_pdf.realEstateGroupLogo = await this.uploadImg(
          this.styles_pdf.realEstateGroupLogo
        );
      }
      if (this.imgValidate(this.styles_pdf.logo_footer)) {
        this.styles_pdf.logo_footer = await this.uploadImg(
          this.styles_pdf.logo_footer
        );
      }

      this.successMessage = "Miscellaneous Actualizado";
      this.errorMessage = "Error al actualizar Miscellaneous";
      const data = {
        business_manager: this.businessManager?._id,
        quote: {
          active: "on",
          active_two: "on",
          styles_view: this.styles_view,
          styles_pdf: this.styles_pdf,
          include_vat: this.includeIva,
          vat: this.iva
        }
      };
      this.$emit("updateQuote", data);
    }
  }
};
</script>
