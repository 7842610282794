import jsPDF from "jspdf";
import { word } from "./multi-language";
import { officeDetail } from "./office-details";
import { superFormat } from "./data-format";
import { addPictures } from "./image-transformation";
import { mergeSchemeTable } from "./data-table";
let language = "spanish";
import {
  configStyle,
  frontPage,
  leftData,
  backCover,
  unitDetail
} from "./style.js";

let doc;
let officeDetailData;
let coordsMap;
let style;
let dataFrontPage;
let packedPriceRule;
let purchaseScheme;
let contactSelected;

export async function generateQuote(structure, type) {
  language = getLanguage(structure);
  switch (type) {
    //LOTES
    case "lots":
      await quoteLots(structure);
      break;
    //OFICINAS
    case "offices":
      await quoteOffice(structure);
      break;
    //DEPARTAMENTOS
    case "department":
      await quoteDepartment(structure);
      break;
    default:
      await quoteOffice(structure);
      break;
  }
  doc.save(
    `${dataFrontPage.nameLead.toUpperCase()}-${dataFrontPage.nameDev.toUpperCase()}.pdf`
  );
  doc = null;
  return true;
}

export async function layout(structure) {
  //Falta agregar el swch..
  doc = jsPDF("l", "mm", [677.3, 381]);
  frontPage(doc, dataFrontPage, structure.real_estate_group_id);
  leftData(doc, contactSelected, dataFrontPage);
  officeDetail(doc, officeDetailData.dataTable, language);
  //Hoja con informacion del lote...
  await addPictures(doc, coordsMap);
  // Si Zenica, agregar información de la Unidad
  if (structure.real_estate_group_id === "610412d0707aaaa569a2000a") {
    await unitDetail(doc, structure);
  }
  mergeSchemeTable(doc, packedPriceRule, purchaseScheme, false, language);
  backCover(doc);
  //doc.output("dataurlnewwindow");
  return doc;
}

//Vista Previa sin el mapeado.. intentar agregarle una imagen de referencia para señalar que alli va la vista previa...
export async function quotePreview(structure, type) {
  language = getLanguage(structure);
  switch (type) {
    //LOTES
    case "lots":
      await quoteLots(structure);
      break;
    //OFICINAS
    case "offices":
      await quoteOffice(structure);
      break;
    //DEPARTAMENTOS
    case "department":
      await quoteDepartment(structure);
      break;
    default:
      await quoteOffice(structure);
      break;
  }
  return doc;
}

function getAllData(structure) {
  officeDetailData = structure.properties
    ? superFormat(structure.properties, language)
    : null;
  if (structure.coords_map) {
    coordsMap = structure.coords_map;
  }
  style = structure.style;
  dataFrontPage = structure.dataFrontPage;
  packedPriceRule = structure.packedPriceRule;
  packedPriceRule.totalMeters = officeDetailData.totalMeters;
  packedPriceRule.nameDev = dataFrontPage.nameDev;
  const developmentName = dataFrontPage?.nameDev?.toLowerCase() ?? "";
  purchaseScheme = structure.purchaseScheme.map(item => {
    if (
      item.text === word(19, language) &&
      ["ikonic tower"].includes(developmentName)
    ) {
      return { ...item, text: word(94, language) };
    }
    return item;
  });
  purchaseScheme = purchaseScheme.map(item => {
    if (
      item.text.includes(word(45, language)) &&
      ["zelva 44"].includes(developmentName)
    ) {
      return { ...item, description: word(95, language) };
    }
    return item;
  });
  contactSelected = structure.contactSelected;
}

//Funciones para generar cotizacion descargable..
function quoteOffice(structure) {
  getAllData(structure, language);
  configStyle(style, language);
  return layout(structure);
}
async function quoteLots(structure) {
  getAllData(structure, language);
  doc = jsPDF("l", "mm", [677.3, 381]);
  configStyle(structure.style, language);
  frontPage(doc, structure.dataFrontPage, structure.real_estate_group_id);
  leftData(doc, structure.contactSelected, structure.dataFrontPage);

  //Hoja con informacion del lote...
  await unitDetail(doc, structure);
  //Hoja con imagenes de mapeado en grande...
  /*   lotsImg(doc, imgLots.imgOne, imgLots.imgTwo);
  addPicturesLots */
  //await addPicturesLots(doc, coordsMap, language, structure);
  mergeSchemeTable(doc, packedPriceRule, purchaseScheme, true, language);
  backCover(doc);
  return doc;
}

async function quoteDepartment(structure) {
  getAllData(structure, language);
  doc = jsPDF("l", "mm", [677.3, 381]);
  configStyle(structure.style, language);
  frontPage(doc, structure.dataFrontPage, structure.real_estate_group_id);
  leftData(doc, structure.contactSelected, structure.dataFrontPage);
  await unitDetail(doc, structure);
  mergeSchemeTable(
    doc,
    packedPriceRule,
    purchaseScheme,
    true,
    language,
    structure.dataFrontPage
  );
  backCover(doc);
  return doc;
}
function getLanguage(structure) {
  return structure?.language ? structure.language : "spanish";
}
