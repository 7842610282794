import { textFont } from "./style";
/**
 * @typedef {Object} Person
 * @property {string} name - Nombre de la persona.
 * @property {string} title - Título o cargo de la persona.
 */

/**
 * Renderiza las firmas en un documento PDF.
 *
 * @param {Object} doc - Objeto `jsPDF` que representa el documento PDF.
 * @param {Person[]} people - Arreglo de personas que deben firmar. Cada objeto en el arreglo debe tener las propiedades
 *
 * @example
 * const doc = new jsPDF();
 * const people = [
 *   { name: "Juan Pérez", title: "Gerente General" },
 *   { name: "Ana López", title: "Directora de Finanzas" }
 * ];
 * renderSignatures(doc, people);
 */
export default function renderSignatures(doc, people) {
  const WIDTH_DOC = doc.internal.pageSize.getWidth();
  const SIGNATURE_WIDTH = 100; // Ancho de cada firma
  const SPACING = 20; // Espaciado entre las firmas
  const TOTAL_SIGNATURE_WIDTH =
    (SIGNATURE_WIDTH + SPACING) * people.length - SPACING;
  const START_X = (WIDTH_DOC - TOTAL_SIGNATURE_WIDTH) / 2;
  const START_Y = doc.lastAutoTable.finalY + 60;

  people.forEach((person, index) => {
    const posX = START_X + index * (SIGNATURE_WIDTH + SPACING);
    createSignature(doc, person, posX, START_Y, SIGNATURE_WIDTH, 20);
  });
}
/**
 * @param {Person} person - Persona que debe firmar.
 */
function createSignature(doc, person, x, y, width, height) {
  const { name, title } = person;
  drawSignatureLine(doc, x, y, width);
  if (name)
    addSignatureText(doc, name.toLocaleUpperCase(), x, y, width, height, {
      font: "bold"
    });
  addSignatureText(doc, title.toLocaleUpperCase(), x, y + 10, width, height);
}

function drawSignatureLine(doc, x, y, width) {
  doc.setLineWidth(0.5);
  doc.line(x, y, x + width, y); // Dibuja la línea de la firma
}

/**
 * @param {Object} [options] - Opciones para el texto.
 * @param {string|null} options.font - Fuente del texto.
 */
function addSignatureText(
  doc,
  name,
  x,
  y,
  width,
  height,
  options = { font: null }
) {
  // Calcula la posición del texto en el centro de la firma
  const TEXT_X = x + width / 2;
  const TEXT_Y = y + height / 2;
  textFont(doc, 14, name, TEXT_X, TEXT_Y, options.font, null, null, {
    align: "center"
  });
}
