import { WORDS, CUSTOM_WORDS, LANGUAGES } from "../../constants/translate";

const DEFAULT_LANGUAGE = "spanish";

class WordTranslator {
  static #instance = null;
  language = DEFAULT_LANGUAGE;
  developName = "";
  indexLanguage = LANGUAGES[this.language];

  /**
   * Crea una instancia de WordTranslator
   * @param {string} language - Idioma de la instancia
   * @param {string} developName - Nombre del desarrollo
   * @example
   * const translator = new WordTranslator("english", "ikonic tower");
   * translator.getWord(5); // "AREA"
   * translator.getWord(27); // "LEAD dweqbdwqgjh"
   * translator.getWord(86); // "TOTAL AREA"
   */
  constructor(language = DEFAULT_LANGUAGE, developName = "") {
    if (WordTranslator.#instance) {
      return WordTranslator.#instance;
    }

    // Validación del idioma
    if (!LANGUAGES.hasOwnProperty(language))
      throw new Error(`Idioma no válido: ${language}`);

    this.language = language;
    this.developName = developName.toLowerCase();
    this.indexLanguage = LANGUAGES[this.language];

    WordTranslator.#instance = this;
  }

  /**
   * Obtiene la palabra en el idioma seleccionado
   * @param {number} index - Índice de la palabra
   * @returns {string} Palabra en el idioma seleccionado o "not found"
   */
  getWord(index) {
    if (!Number.isInteger(index) || index >= WORDS.length) {
      return "not found";
    }

    // Buscar palabra personalizada
    const customWord =
      CUSTOM_WORDS[this.developName]?.[index]?.[this.indexLanguage];
    if (customWord) return customWord;

    // Buscar palabra global
    const globalWord = WORDS[index]?.[this.indexLanguage];
    return globalWord || "not found";
  }

  /**
   * Método para obtener la instancia única
   * @param {string} language
   * @param {string} developName
   * @returns {WordTranslator} Instancia única
   */
  static getInstance(language = DEFAULT_LANGUAGE, developName = "") {
    if (!WordTranslator.#instance) {
      WordTranslator.#instance = new WordTranslator(language, developName);
    }
    return WordTranslator.#instance;
  }

  /**
   * Cambia el idioma de la instancia
   * @param {string} newLanguage
   */
  setLanguage(newLanguage) {
    if (!LANGUAGES.hasOwnProperty(newLanguage)) {
      throw new Error(`Idioma no válido: ${newLanguage}`);
    }
    this.language = newLanguage;
    this.indexLanguage = LANGUAGES[this.language];
  }

  /**
   * Cambia el nombre del desarrollo de la instancia
   * @param {string} newDevelopName
   */
  setDevelopName(newDevelopName) {
    this.developName = newDevelopName.toLowerCase() || "";
  }
}
/**
 * Helper para obtener palabras en el idioma seleccionado.
 *
 * Esta función es un wrapper de la clase WordTranslator para obtener palabras en el idioma seleccionado.
 * Dado que la clase WordTranslator es un singleton, esta función se encarga de crear una instancia única y devolver la palabra solicitada.
 *
 * @param {number} n - Índice de la palabra
 * @returns {string} Palabra en el idioma seleccionado o "not found"
 */
export const word = n => {
  const translator = WordTranslator.getInstance();
  return translator.getWord(n);
};

export default WordTranslator;
