<template>
  <v-dialog v-model="show" persistent max-width="1000px">
    <v-card>
      <v-card-title primary-title>
        {{ type }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form" action="">
          <v-row align="center" justify="center">
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model="pricing_rules.description"
                label="TÍtulo"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" md="6" sm="6">
              <v-select
                :items="listPropertyCode"
                v-model="pricing_rules.propertys_code"
                clearable
                chips
                color="#042956"
                prepend-icon="mdi-city"
                item-text="name"
                label="Propiedades (Por defecto: todas)"
                multiple
                deletable-chips
              >
                <template #selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption"
                    >(+{{
                      pricing_rules.propertys_code.length - 1
                    }}
                    otros)</span
                  >
                </template>
                <template #item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.floor
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-select
                :items="sltprocessType"
                v-model="pricing_rules.process_type"
                item-text="name"
                item-value="value"
                label="Tipo de contrato"
              ></v-select>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" md="6" sm="6">
              <v-select
                :items="sltmethodPayment"
                v-model="pricing_rules.method_payment"
                item-text="name"
                item-value="value"
                label="Elige tu forma de pago"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                label="Descripción de la forma de pago"
                v-model="pricing_rules.description_method_payment"
                type="text"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                label="Financiamiento"
                v-model="pricing_rules.financing"
                prefix="%"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                label="Intereses"
                v-model="pricing_rules.financing_interest"
                prefix="%"
                type="number"
                :disabled="
                  pricing_rules.method_payment !== 'financing-interest'
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                label="Meses acordados"
                v-model="pricing_rules.months_agreed"
                prefix="#"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                label="Gasto administrativo"
                v-model="pricing_rules.admin_cost"
                prefix="$"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                :label="mpDepositLabel"
                v-model="pricing_rules.guarantee_deposit"
                prefix="$"
                type="number"
                :disabled="pricing_rules.propertys_code.length > 1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                label="Descripción del apartado"
                v-model="pricing_rules.description_guarantee_deposit"
                type="text"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                label="Enganche"
                v-model="pricing_rules.percentage_down_payment"
                prefix="%"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                label="Descripción del enganche"
                v-model="pricing_rules.description_percentage_down_payment"
                type="text"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                label="Finiquito contra entrega"
                v-model="pricing_rules.termination_payment"
                prefix="%"
                type="number"
                :disabled="pricing_rules.propertys_code.length > 1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                label="Descripción del finiquito de entrega"
                v-model="pricing_rules.description_termination_payment"
                type="text"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" md="6" sm="6">
              <v-switch
                v-model="pricing_rules.apply_discount"
                :input-value="pricing_rules.apply_discount"
                label="Aplicar descuento(s)"
                @change="value => changeActiveDiscount(value)"
              ></v-switch>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                label="Cantidad de descuentos permitidos"
                v-model="pricing_rules.number_apply_discount"
                prefix="#"
                type="number"
                :disabled="pricing_rules.apply_discount !== true"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" md="12" sm="12">
              <v-select
                :items="listDiscount"
                v-model="pricing_rules.discounts"
                clearable
                chips
                color="#042956"
                prepend-icon="mdi-sale"
                item-text="name"
                label="Agregar descuento(s)"
                :disabled="pricing_rules.apply_discount !== true"
                multiple
                :counter="pricing_rules.number_apply_discount"
                v-on:input="limiter"
                deletable-chips
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggle">
                    <v-list-item-action>
                      <v-icon
                        :color="
                          pricing_rules.discounts.length > 0
                            ? 'indigo darken-4'
                            : ''
                        "
                        >{{ icon }}</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Todos</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption"
                    >(+{{ pricing_rules.discounts.length - 1 }} otros)</span
                  >
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />

        <v-btn color="error" @click="close" dark class="mb-2">
          Cancelar
        </v-btn>
        <v-btn color="success" @click="action" dark class="mb-2">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const initPricing_rules = {
  process_type: "",
  description: "",
  method_payment: "",
  financing: "",
  financing_interest: "",
  percentage_down_payment: "",
  description_percentage_down_payment: "",
  guarantee_deposit: "",
  description_guarantee_deposit: "",
  months_agreed: "",
  montly_payment: "",
  description_method_payment: "",
  termination_payment: "",
  description_termination_payment: "",
  propertys_code: [],
  apply_discount: false,
  number_apply_discount: "0",
  discounts: [],
  admin_cost: 0
};

export default {
  name: "ModalPricingRules",
  props: {
    listPropertyCode: { type: Array, default: () => [] },
    listDiscount: { type: Array, default: () => [] },
    value: { type: Object },
    show: { type: Boolean },
    type: { type: String } // create or update
  },
  emits: ["action", "close"],
  data() {
    return {
      pricing_rules: { ...initPricing_rules },
      sltprocessType: [
        // {
        //   name: "Renta",
        //   value: "rent"
        // },
        {
          name: "Venta",
          value: "sale"
        },
        {
          name: "Preventa",
          value: "pre-sale"
        }
      ],
      sltmethodPayment: [
        {
          name: "Financiamiento",
          value: "financing"
        },
        {
          name: "Financiamiento con intereses (sobre saldos insolutos)",
          value: "financing-interest"
        },
        {
          name: "Contado",
          value: "counted"
        }
      ]
    };
  },
  watch: {
    value: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.pricing_rules = { ...newVal };
        } else {
          this.pricing_rules = { ...initPricing_rules };
        }
      }
    }
  },
  computed: {
    mpDepositLabel() {
      return this.pricing_rules.process_type == "rent"
        ? "Deposito"
        : "Apartado";
    },
    icon() {
      if (this.propertyAllSelected) return "mdi-close-box";
      if (this.propertySelected) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    }
  },
  methods: {
    changeActiveDiscount(valor) {
      if (valor) {
        this.pricing_rules.number_apply_discount = 1;
      } else {
        this.pricing_rules.number_apply_discount = 0;
        this.pricing_rules.discounts = [];
      }
    },
    limiter(e) {
      let number = this.pricing_rules.number_apply_discount;
      number = number ? number : 0;
      if (e.length > number) {
        e.pop();
      }
    },
    toggle() {
      if (this.propertyAllSelected) {
        this.pricing_rules.propertys_code = [];
      } else {
        this.pricing_rules.propertys_code = this.listPropertyCode.map(
          property => property.name
        );
      }
    },
    action() {
      this.$emit("input", this.pricing_rules);
      this.$emit("action");
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>
