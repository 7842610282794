import autoTable from "jspdf-autotable";
import { word } from "./multi-language";
import { background, title, botttomLogo, colors, textFont } from "./style";
let language = "spanish";
let colorsA = {};

function offerTable(doc, data) {
  const process_type =
    data.seletedPriceRules.process_type == "pre-sale"
      ? word(64, language)
      : word(65, language);
  title(doc, `${process_type.toUpperCase()}`, 5, 10);
  const offer = [
    {
      one: `${word(53, language)}`,
      two: `${data.currency} ${currency(data.initPriceSIva)}`,
      three: `${word(54, language)}`,
      four: `${data.currency} ${currency(data.initPrice)}`
    },
    {
      one: `${word(55, language)}`,
      two: `${data.currency} ${currency(
        data.initPriceSIva / data.totalMeters
      )}`,
      three: "Total m²",
      four: `${data.totalMeters.toFixed(2)} m²`
    }
  ];
  autoTable(doc, {
    theme: "plain",
    startY: 50,
    body: offer,
    columns: [
      { dataKey: "one" },
      { dataKey: "two" },
      { dataKey: "three" },
      { dataKey: "four" }
    ],
    margin: { left: 50, right: 50, top: 100 },
    styles: {
      fontSize: 28,
      textColor: colorsA.text_color,

      cellPadding: 3
    }
  });
}
/**
 * Convert a number to currency format
 * @param {number} number - number to format
 * @returns {string} - number formatted as currency
 */
function currency(number) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0
  }).format(number);
}

// aqui se hace la tabla
export function mergeSchemeTable(
  doc,
  packedRule,
  schema,
  workWithoutVAT,
  lang,
  dataFront
) {
  if (lang) language = lang;
  colorsA = colors();
  let startYmergeTable = 80;
  //const dev = packedRule.nameDev.toUpperCase();
  let nameScheme = "";

  if (
    packedRule.seletedPriceRules &&
    packedRule.seletedPriceRules.description
  ) {
    nameScheme = packedRule.seletedPriceRules.description.toUpperCase();
  }
  const currencyType = packedRule.currency;

  doc.addPage();
  background(doc);
  if (!workWithoutVAT) {
    offerTable(doc, packedRule);
    title(doc, `${word(6, language)}: ${nameScheme}`, 5, 35);
    startYmergeTable = 150;
  } else {
    title(doc, `${word(6, language)}: ${nameScheme}`, 5, 10);
  }
  const finalPrice = word(36, language);
  const head = schema.filter(item => item.text == finalPrice);
  const months = schema.filter(item => item.text === `# ${word(45, language)}`);
  const body = schema.filter(
    item => item.text != finalPrice && item.text !== `# ${word(45, language)}`
  );

  const schemaFormat = [...head, ...[{}], ...body, ...[{}], ...months];
  const discountsData = discounts(packedRule);

  const dataTable = [...discountsData, ...schemaFormat];

  const positionFinalPrice = dataTable.findIndex(
    item => item.text == finalPrice
  );

  const adminCost = packedRule.seletedPriceRules.admin_cost;

  if (adminCost) {
    dataTable.splice(positionFinalPrice + 1, 0, {
      text: `${word(103, language)}`,
      percent: "",
      description: "",
      amount: `${currencyType} ${currency(adminCost)}`
    });
  }

  autoTable(doc, {
    startY: startYmergeTable,
    theme: "plain",
    columns: [
      { dataKey: "text" },
      { dataKey: "percent" },
      { dataKey: "description" },
      { dataKey: "amount" }
    ],
    body: dataTable,
    styles: { fontSize: 28, textColor: colorsA.text_color },
    //bodyStyles: { lineColor: "#F2F3F4" },
    margin: { left: 50, right: 50, top: 100 },
    didParseCell: data => {
      //Desarrollar funcion para poder cambiar de color en diferentes filas..

      if (data.row.index == positionFinalPrice) {
        data.cell.styles.textColor = colorsA.title_color;
        data.cell.styles.fillColor = `${colorsA.container_two}`;
      }
    }
  });
  if (doc.lastAutoTable.finalY < 345) {
    botttomLogo(doc, dataFront?.nameDev?.toLowerCase() ?? "");
  }
  const developmentName = dataFront?.nameDev?.toLowerCase() ?? "";

  if (developmentName === "ikonic tower") {
    const isMNXAmount = currencyType === "MXN";
    const textAmount = isMNXAmount ? 91 : 105;
    textFont(doc, 25, word(90), 8, 74, "", "#ffffff", true);
    textFont(doc, 25, word(textAmount), 62, 74, "", "#ffffff", true);
    !isMNXAmount && textFont(doc, 25, word(104), 62, 77, "", "#ffffff", true);
  }

  //Datos mergeados de packedPriceeRuleTable y PurchaseSchemeTable..
}
function discounts(data) {
  const process_type =
    data.seletedPriceRules.process_type == "pre-sale"
      ? word(62, language)
      : word(63, language);
  const presale = [
    {
      text: `${process_type}`,
      percent: "",
      description: "",
      amount: `${data.currency} ${currency(data.initPriceSIva)}`
    },
    {}
  ];

  let totalDiscounts = [];
  if (data.totalDiscounts) {
    totalDiscounts = [
      {
        text: `${word(47, language)} ${data.currency}`,
        percent: "",
        description: "",
        amount: `${data.currency} ${currency(data.totalDiscounts)}`
      },
      {}
    ];
  }
  const priceRules = [
    ...data.priceRuleApplied.priceRulesDiscount,
    ...data.priceRuleApplied.extraDiscounts
  ];

  let format = [];
  priceRules.forEach(a => {
    const dts = {
      text: a.text,
      percent: "",
      description: "",
      amount: a.percent
    };
    format.push(dts);
  });

  return [...presale, ...format, ...totalDiscounts];
}
